export const NAVBAR_LINKS = [
  {
    title: "home",
    path: "/",
    color: "#ffffff"
  },
  {
    title: "casa florinda",
    path: "/casa-florinda",
    color: "#9747FF"
  },
  {
    title: "love shack",
    path: "/love-shack",
    color: "#2C96EA"
  },
  {
    title: "quinta souza",
    path: "/quinta-souza",
    color: "#06A9A3"
  },
];
