export const FOOTER_LINKS = {
  "family businesses": [
    { item: "Souza lopes Comforts", url: "https://souzalopescomforts.com" },
    { item: "Love shack", path: "/love-shack" },
    { item: "Quinta Souza",path: "/quinta-souza" },
  ],
  other: [
    { item: "contact us", path: "/contact-us" },
    { item: "privacy policy", path: "/privacy" },
  ],
};